var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var OH,QH,SH,aI,dI,iI;$CLJS.NH=new $CLJS.M(null,"get-month","get-month",-369374731);OH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.PH=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);QH=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.RH=new $CLJS.M(null,"iso","iso",-1366207543);SH=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.TH=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.UH=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.VH=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.WH=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.XH=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.YH=new $CLJS.M(null,"us","us",746429226);$CLJS.ZH=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.$H=new $CLJS.M(null,"now","now",-1650525531);
aI=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.bI=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.cI=new $CLJS.M(null,"get-day","get-day",127568857);dI=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.eI=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.fI=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.gI=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.hI=new $CLJS.M(null,"get-hour","get-hour",622714059);iI=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.jI=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.kI=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.lI=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.mI=new $CLJS.M(null,"target","target",253001721);$CLJS.wE($CLJS.ZA,$CLJS.H([$CLJS.Zr,$CLJS.aC,$CLJS.pr,$CLJS.dD]));$CLJS.KD.m(null,$CLJS.WH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.LD(a);return $CLJS.qd(a)?(a=$CLJS.ct.g(a,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Gk,null,$CLJS.Ik,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.z(a):a):a});$CLJS.pE($CLJS.WH,$CLJS.bE);
for(var nI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bB,$CLJS.eB],null)),oI=null,pI=0,qI=0;;)if(qI<pI){var rI=oI.X(null,qI);$CLJS.wE(rI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null),$CLJS.pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dD],null)]));$CLJS.pE(rI,$CLJS.WH);qI+=1}else{var sI=$CLJS.y(nI);if(sI){var tI=sI;if($CLJS.vd(tI)){var uI=$CLJS.ic(tI),xia=$CLJS.jc(tI),yia=uI,zia=$CLJS.D(uI);nI=xia;oI=yia;pI=zia}else{var vI=$CLJS.z(tI);$CLJS.wE(vI,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.UD],null),$CLJS.pr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dD],null)]));$CLJS.pE(vI,$CLJS.WH);nI=$CLJS.B(tI);oI=null;pI=0}qI=0}else break}
for(var wI=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.UH,$CLJS.NH,$CLJS.cI,$CLJS.hI,$CLJS.eI,$CLJS.TH,$CLJS.jI],null)),xI=null,yI=0,zI=0;;)if(zI<yI){var Aia=xI.X(null,zI);$CLJS.wE(Aia,$CLJS.H([$CLJS.Zr,$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));zI+=1}else{var AI=$CLJS.y(wI);if(AI){var BI=AI;if($CLJS.vd(BI)){var CI=$CLJS.ic(BI),Bia=$CLJS.jc(BI),Cia=CI,Dia=$CLJS.D(CI);wI=Bia;xI=Cia;yI=Dia}else{var Eia=$CLJS.z(BI);$CLJS.wE(Eia,$CLJS.H([$CLJS.Zr,
$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));wI=$CLJS.B(BI);xI=null;yI=0}zI=0}else break}$CLJS.wE($CLJS.YA,$CLJS.H([$CLJS.Zr,$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XF],null)]));
for(var DI=$CLJS.y(new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.jI,null,$CLJS.eI,null,$CLJS.hI,null,$CLJS.fI,null,$CLJS.TH,null,$CLJS.NH,null,$CLJS.UH,null,$CLJS.cI,null],null),null)),EI=null,FI=0,GI=0;;)if(GI<FI){var Fia=EI.X(null,GI);$CLJS.wE(Fia,$CLJS.H([$CLJS.Zr,$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));GI+=1}else{var HI=$CLJS.y(DI);if(HI){var II=HI;if($CLJS.vd(II)){var JI=$CLJS.ic(II),Gia=$CLJS.jc(II),Hia=JI,Iia=$CLJS.D(JI);
DI=Gia;EI=Hia;FI=Iia}else{var Jia=$CLJS.z(II);$CLJS.wE(Jia,$CLJS.H([$CLJS.Zr,$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)]));DI=$CLJS.B(II);EI=null;FI=0}GI=0}else break}$CLJS.Y(iI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.RH,$CLJS.YH,$CLJS.bI],null));
$CLJS.yE($CLJS.aB,$CLJS.H([$CLJS.Zr,$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iI],null)],null)],null)],null)]));
$CLJS.Y($CLJS.gI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,$CLJS.qD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,2,[$CLJS.ts,"valid timezone ID",$CLJS.Es,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.gj);return["invalid timezone ID: ",$CLJS.xh.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.Cz($CLJS.Dd,$CLJS.MH.tz.names())),$CLJS.DH],null)],null));
$CLJS.yE($CLJS.PH,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mI,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yy,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gI],null)],null)],null)],
null)]));$CLJS.pE($CLJS.PH,$CLJS.WH);$CLJS.wE($CLJS.$H,$CLJS.H([$CLJS.Zr,$CLJS.Hk]));$CLJS.Y(SH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.Dz(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gk,$CLJS.Ik],null))}],null)],null));
$CLJS.Y(OH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SH],null)],null)],null)],null));
$CLJS.IF.g($CLJS.VH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.ts,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.VH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vq,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.JH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YF],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Tq,new $CLJS.h(null,1,[$CLJS.ts,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Qu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Oh],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UF],null)],null)],null)],null)],null)],null));
$CLJS.KD.m(null,$CLJS.VH,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.Qu)?$CLJS.E.g(a,$CLJS.Oh)?$CLJS.Ik:$CLJS.n($CLJS.oE($CLJS.YF,a))?$CLJS.Gk:$CLJS.Ik:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.dh($CLJS.xH,b))?$CLJS.Gk:$CLJS.n($CLJS.dh($CLJS.yH,b))?$CLJS.Gk:null:null;if($CLJS.n(a))return a;b=$CLJS.LD(b);b=$CLJS.qd(b)?$CLJS.Wf.j($CLJS.Sg,$CLJS.ef(function(c){return $CLJS.Dz(c,$CLJS.vj)}),b):b;return $CLJS.qd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.z(b):b});$CLJS.Y(dI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Qu],null),$CLJS.pr],null));
$CLJS.yE($CLJS.$A,$CLJS.H([$CLJS.Zr,$CLJS.Ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dD],null)],null)],null)],null)]));
$CLJS.wE($CLJS.Fx,$CLJS.H([$CLJS.Zr,$CLJS.Lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cD],null)]));$CLJS.Y(QH,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.br,$CLJS.WF,$CLJS.ki,$CLJS.Fj,$CLJS.XH,$CLJS.kI,$CLJS.lI,$CLJS.jj,$CLJS.pi,$CLJS.gi,$CLJS.Si,$CLJS.TF],null));$CLJS.Y(aI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.RH,$CLJS.YH,$CLJS.bI],null));
$CLJS.yE($CLJS.cB,$CLJS.H([$CLJS.Zr,$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UD],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.di,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aI],null)],null)],
null)],null)]));