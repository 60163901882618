var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Vqa,Wqa,Xqa,Yqa,j3,k3,l3,m3,Zqa,$qa,ara,bra,cra,dra,era,fra,gra,w3,x3,hra,ira,jra,v3,u3,kra,y3;$CLJS.d3=function(a,b){$CLJS.cb(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Vqa=function(a){return function(b){var c=$CLJS.Ve(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.dl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Wqa=function(a,b){return function f(d,e){return new $CLJS.ie(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Cd(k,t))l=$CLJS.Hc(m);else{k=$CLJS.ae(l,f($CLJS.Hc(m),$CLJS.Yd.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.Sg)};
Xqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Ra($CLJS.y($CLJS.Bz(c,d)))?c:$CLJS.Ne.M($CLJS.uW,c,d,e,b)};$CLJS.e3=function(a){return(0,$CLJS.ma)($CLJS.Gz(a,$CLJS.Wma,""))};$CLJS.f3=function(a,b){return $CLJS.s0.g(a,b instanceof $CLJS.M?b:$CLJS.di.h(b))};
$CLJS.g3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.kk.g($CLJS.oV,d);var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h(function(f){return $CLJS.T1.v(a,b,f,c)}),d);return $CLJS.kk.g(function(f){return $CLJS.R.j(f,$CLJS.LY,$CLJS.Cd(e,f))},c)}return null};$CLJS.h3=function(a){return $CLJS.E.g($CLJS.XA(a),$CLJS.kK)};Yqa=function(a,b){return $CLJS.n($CLJS.RD.h(a))?$CLJS.lk.j(a,$CLJS.RD,function(c){return $CLJS.rd(c)?$CLJS.kk.g(function(d){return $CLJS.i3.g?$CLJS.i3.g(d,b):$CLJS.i3.call(null,d,b)},c):c}):a};
j3=function(a){if($CLJS.n(a)){var b=$CLJS.ff;a:try{if($CLJS.ud(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.ud(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ce(d,$CLJS.jF))try{var e=$CLJS.F(a,3);if($CLJS.ud(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ce(f,$CLJS.jF)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Z;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Z)throw $CLJS.Z;throw l;}throw m;}else throw $CLJS.Z;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Z){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.z($CLJS.y(b($CLJS.Sa,k)))}else a=null;return a};k3=function(a){if($CLJS.n(j3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};l3=function(a){return $CLJS.n(j3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
m3=function(a,b){var c=$CLJS.i3;if($CLJS.Ra(j3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Ne.j(c,a,b)],null)};
Zqa=function(a,b,c){return $CLJS.od($CLJS.sK.h(a))?a:$CLJS.n(b)?Xqa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.sK],null),function(d){return function l(f,k){try{if($CLJS.ud(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ce(m,$CLJS.jF))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.yc===t.mf||(t.C?0:$CLJS.Ya($CLJS.ub,t)):$CLJS.Ya($CLJS.ub,t))try{var u=$CLJS.J.j(t,$CLJS.sO,$CLJS.BU);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.i3.g?$CLJS.i3.g(k,c):$CLJS.i3.call(null,k,c);throw $CLJS.Z;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)return $CLJS.WV(l,f,k);throw v;}throw x;}}($CLJS.xf,d)}):$CLJS.lk.j(a,$CLJS.sK,function(d){return $CLJS.kk.g(function(e){return m3(e,$CLJS.H([c]))},d)})};
$CLJS.i3=function(a,b){var c=$CLJS.XA(a);switch(c instanceof $CLJS.M?c.S:null){case "field":return $CLJS.FD(a,$CLJS.DD,$CLJS.H([$CLJS.sO,b]));case "metadata/column":return $CLJS.DD(a,$CLJS.dZ,b);case "mbql/join":return c=$CLJS.h1(a),Zqa(Yqa($CLJS.DD(a,$CLJS.VD,b),b),c,b);default:return b=$CLJS.Mz($CLJS.Cx),$CLJS.n($CLJS.Lz("metabase.lib.join",b))&&$CLJS.Kz("metabase.lib.join",b,$CLJS.xv.l($CLJS.H(["with-join-value should not be called with",$CLJS.xh.l($CLJS.H([a]))])),null),a}};
$CLJS.n3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.zz($CLJS.tY,$CLJS.U)(c);a=$CLJS.i1($CLJS.VD.h(a),$CLJS.zz($CLJS.tY,$CLJS.U)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.tY,e,$CLJS.H([$CLJS.JY,b]))};$qa=function(a,b){return $CLJS.Ra(b)?a:$CLJS.kk.g(function(c){var d=l3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.h1(d))?null:m3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.o3=function(a,b){b=$qa($CLJS.kk.g($CLJS.pV,b),$CLJS.h1(a));return $CLJS.DD(a,$CLJS.sK,$CLJS.Be(b))};
$CLJS.p3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.xf)?$CLJS.cz:$CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ik.g($CLJS.cf.h($CLJS.oV),function(){var c=$CLJS.h1(a);return $CLJS.n(c)?$CLJS.cf.h(function(d){return $CLJS.i3(d,c)}):$CLJS.Pd}()),b));return $CLJS.DD(a,$CLJS.RD,b)};
ara=function(a,b){b=$CLJS.Al(function(d){return $CLJS.T1.g(d,a)},b);var c=$CLJS.qG($CLJS.zz($CLJS.JZ,$CLJS.KZ),b);if($CLJS.n(c))return c;b=$CLJS.z(b);if($CLJS.n(b))return b;b=$CLJS.qG($CLJS.JZ,a);if($CLJS.n(b))return b;b=$CLJS.qG($CLJS.KZ,a);return $CLJS.n(b)?b:$CLJS.z(a)};bra=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.Gz(a,/ id$/i,"")):null};
cra=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.uh(function(){return $CLJS.o2(b)});var d=new $CLJS.uh(function(){return $CLJS.o2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
dra=function(a,b,c){var d=$CLJS.k_.g($CLJS.E.g($CLJS.Vs.h(b),$CLJS.fV)?b:a,b),e=$CLJS.n(c)?bra($CLJS.k_.g(a,c)):null,f=cra(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Ra($CLJS.dh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
era=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.G1(l,$CLJS.jF)&&$CLJS.zd($CLJS.T1.v(a,b,l,e)))return $CLJS.i3(l,d);throw $CLJS.Z;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Z)return $CLJS.WV(m,k,l);throw t;}throw u;}}($CLJS.xf,c)};
fra=function(a,b,c,d,e,f){c=era(a,b,c,d,f);return function t(l,m){try{if($CLJS.ud(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.G1(u,$CLJS.jF))try{var v=$CLJS.F(m,3);if($CLJS.G1(v,$CLJS.jF)){var x=$CLJS.F(m,3),A=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.h1(A),S=$CLJS.h1(x);if($CLJS.Ra($CLJS.n(K)?K:S))return $CLJS.n($CLJS.T1.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.i3(A,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,$CLJS.i3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var X=
$CLJS.FD(A,$CLJS.bk,$CLJS.H([$CLJS.sO])),T=$CLJS.FD(x,$CLJS.bk,$CLJS.H([$CLJS.sO])),da=null==$CLJS.T1.v(a,b,X,e);var oa=da?$CLJS.T1.v(a,b,T,e):da;return $CLJS.n(oa)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,A,T],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,X,x],null)}return m}throw $CLJS.Z;}catch(sb){if(sb instanceof Error){var Ma=sb;if(Ma===$CLJS.Z)throw $CLJS.Z;throw Ma;}throw sb;}else throw $CLJS.Z;}catch(sb){if(sb instanceof Error){Ma=sb;if(Ma===$CLJS.Z)throw $CLJS.Z;throw Ma;}throw sb;}else throw $CLJS.Z;
}catch(sb){if(sb instanceof Error){Ma=sb;if(Ma===$CLJS.Z)return $CLJS.WV(t,l,m);throw Ma;}throw sb;}}($CLJS.xf,c)};gra=function(a,b){var c=$CLJS.GV();$CLJS.d3(c,b);return c(a)};
$CLJS.q3=function(a,b,c){if($CLJS.Cd(c,$CLJS.VD))return c;var d=$CLJS.DV(a,b),e=$CLJS.X_.j(a,b,d),f=$CLJS.y($CLJS.ff($CLJS.Sa,function v(t,u){try{if($CLJS.ud(u)&&1<=$CLJS.D(u))try{var x=$CLJS.pk.j(u,0,1);if($CLJS.ud(x)&&1===$CLJS.D(x))try{var A=$CLJS.F(x,0);if($CLJS.ce(A,$CLJS.jF))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Z;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Z)throw $CLJS.Z;throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)throw $CLJS.Z;
throw C;}throw G;}else throw $CLJS.Z;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Z)return $CLJS.yU(v,t,u);throw C;}throw G;}}($CLJS.xf,$CLJS.sK.h(c))));f=ara(e,f);var k=gra(dra(a,c,f),$CLJS.Al($CLJS.VD,$CLJS.CM.h(d))),l=$CLJS.V_.h($CLJS.b1.g(a,$CLJS.pK.h(c)));return $CLJS.i3($CLJS.lk.j(c,$CLJS.sK,function(t){return $CLJS.kk.g(function(u){return fra(a,b,u,k,e,l)},t)}),k)};$CLJS.r3=function(a){return $CLJS.sK.h(a)};
$CLJS.s3=function(a){return $CLJS.Ak.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.tK,$CLJS.BD,a],null),$CLJS.E.g(a,$CLJS.$D)?new $CLJS.h(null,1,[$CLJS.Oh,!0],null):null]))};$CLJS.t3=function(a,b){b=$CLJS.z($CLJS.pK.h(b));return $CLJS.n($CLJS.MU.h(b))?$CLJS.e_(a,$CLJS.MU.h(b)):$CLJS.n($CLJS.dO.h(b))?$CLJS.c_(a,$CLJS.dO.h(b)):null};
w3=function(a){a=$CLJS.pG(function(d){return $CLJS.KZ(d)?u3:$CLJS.JZ(d)?v3:$CLJS.Rs},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,u3);var c=$CLJS.J.g(b,v3);b=$CLJS.J.g(b,$CLJS.Rs);return $CLJS.bf.l(a,c,$CLJS.H([b]))};x3=function(a,b,c,d){return $CLJS.Ra(c)?d:$CLJS.kk.g(function(e){return $CLJS.n($CLJS.LY.h(e))?$CLJS.f3(e,$CLJS.m2(c)):e},$CLJS.g3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
hra=function(a,b,c,d){var e=new $CLJS.uh(function(){return $CLJS.X_.v(a,b,d,new $CLJS.h(null,2,[$CLJS.A_,!1,$CLJS.I_,!1],null))});return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ul(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.KY);return $CLJS.n(function(){var m=$CLJS.JZ(k);return m?l:m}())?(f=$CLJS.qG(function(m){return $CLJS.E.g(l,$CLJS.Ji.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,y3,f):null):null}),$CLJS.X_.v(a,b,c,new $CLJS.h(null,2,[$CLJS.A_,!1,$CLJS.I_,!1],null))))};ira={};
jra=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);v3=new $CLJS.M(null,"fk","fk",398442651);u3=new $CLJS.M(null,"pk","pk",-771936732);kra=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);y3=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.L_.m(null,$CLJS.kK,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.pK);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.dO);b=$CLJS.J.g(b,$CLJS.MU);c=$CLJS.n(c)?$CLJS.lD.h($CLJS.c_(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.e_(a,b),a=$CLJS.n(c)?$CLJS.k_.j(a,0,c):$CLJS.m1(b)):a=null;return $CLJS.n(a)?a:$CLJS.ID("Native Query")});$CLJS.T_.m(null,$CLJS.kK,function(a,b,c){a=$CLJS.k_.j(a,b,c);c=$CLJS.VD.h(c);return new $CLJS.h(null,2,[$CLJS.U,$CLJS.n(c)?c:a,$CLJS.lD,a],null)});
$CLJS.S_.m(null,$CLJS.kK,function(){throw $CLJS.Uh("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.L_.m(null,$CLJS.tK,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.BD);a=a instanceof $CLJS.M?a.S:null;switch(a){case "left-join":return $CLJS.ID("Left outer join");case "right-join":return $CLJS.ID("Right outer join");case "inner-join":return $CLJS.ID("Inner join");case "full-join":return $CLJS.ID("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.T_.m(null,$CLJS.tK,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.BD);c=$CLJS.J.g(d,$CLJS.Oh);a=new $CLJS.h(null,2,[$CLJS.J_,$CLJS.$z(e),$CLJS.lD,$CLJS.k_.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.Oh,!0):a});
$CLJS.U_.m(null,$CLJS.kK,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.VD),k=$CLJS.J.j(e,$CLJS.RD,$CLJS.cz),l=$CLJS.J.g(e,$CLJS.pK);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.G_);if($CLJS.E.g(k,$CLJS.cz))return null;var t="undefined"!==typeof $CLJS.eL&&"undefined"!==typeof ira&&"undefined"!==typeof $CLJS.lra&&"undefined"!==typeof $CLJS.z3?new $CLJS.Cc(function(){return $CLJS.z3},$CLJS.kd(kra,new $CLJS.h(null,1,[$CLJS.yj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.pK,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.wx)?$CLJS.V_.v(u,-1,$CLJS.md(l),c):function(){return function A(x){return new $CLJS.ie(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.vd(C)){var G=$CLJS.ic(C),K=$CLJS.D(G),S=$CLJS.le(K);a:for(var X=0;;)if(X<K){var T=$CLJS.hd(G,X);T=$CLJS.FD(T,$CLJS.bk,$CLJS.H([$CLJS.sO]));T=$CLJS.i_.j(u,-1,T);S.add(T);X+=1}else{G=!0;break a}return G?$CLJS.oe($CLJS.qe(S),A($CLJS.jc(C))):$CLJS.oe($CLJS.qe(S),null)}S=$CLJS.z(C);S=$CLJS.FD(S,$CLJS.bk,$CLJS.H([$CLJS.sO]));
return $CLJS.ae($CLJS.i_.j(u,-1,S),A($CLJS.Hc(C)))}return null}},null,null)}(k)}();return $CLJS.kk.g(function(v){v=$CLJS.R.j(v,$CLJS.YY,f);v=$CLJS.i3($CLJS.R.l(v,$CLJS.lD,$CLJS.k_.j(a,b,v),$CLJS.H([$CLJS.LJ,$CLJS.GY])),f);return $CLJS.n3(e,m,v)},c)});$CLJS.W_.m(null,$CLJS.kK,function(a,b,c,d){return $CLJS.V_.v(a,b,$CLJS.R.j(c,$CLJS.RD,$CLJS.wx),d)});
var A3,mra=$CLJS.Se($CLJS.N),nra=$CLJS.Se($CLJS.N),ora=$CLJS.Se($CLJS.N),pra=$CLJS.Se($CLJS.N),qra=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ri,$CLJS.gB],null),$CLJS.Ri,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));A3=new $CLJS.Ph($CLJS.qh.g("metabase.lib.join","join-clause-method"),$CLJS.XA,qra,mra,nra,ora,pra);A3.m(null,$CLJS.kK,function(a){return a});A3.m(null,$CLJS.fV,function(a){return $CLJS.nV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.kK,$CLJS.pK,$CLJS.pK.h($CLJS.BV(a))],null))});
A3.m(null,$CLJS.TU,function(a){return $CLJS.nV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.kK,$CLJS.pK,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});A3.m(null,$CLJS.gK,function(a){return $CLJS.nV(new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.kK,$CLJS.pK,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.MU,$CLJS.Ji.h(a),$CLJS.Vs,$CLJS.TU],null)],null)],null))});
A3.m(null,$CLJS.RJ,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.dZ);a=$CLJS.J.g(b,jra);b=A3.h(new $CLJS.h(null,3,[$CLJS.Vs,$CLJS.TU,$CLJS.uA,new $CLJS.h(null,1,[$CLJS.xD,$CLJS.p.h($CLJS.HD())],null),$CLJS.dO,$CLJS.Ji.h(b)],null));c=$CLJS.n(c)?$CLJS.i3(b,c):b;return $CLJS.n(a)?$CLJS.p3.g?$CLJS.p3.g(c,a):$CLJS.p3.call(null,c,a):c});
$CLJS.B3=function(){function a(d,e){return $CLJS.o3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.E1(A3.h(d),$CLJS.RD,$CLJS.wx)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.rra=function(){function a(d,e,f){var k=$CLJS.B3.h(f);f=$CLJS.od($CLJS.r3.h?$CLJS.r3.h(k):$CLJS.r3.call(null,k))?function(){var m=$CLJS.t3.g?$CLJS.t3.g(d,k):$CLJS.t3.call(null,d,k);return $CLJS.C3.j?$CLJS.C3.j(d,e,m):$CLJS.C3.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.o3(k,f):k;var l=$CLJS.q3(d,e,f);return $CLJS.OV.l(d,e,$CLJS.lk,$CLJS.H([$CLJS.CM,function(m){return $CLJS.Yd.g($CLJS.yf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.D3=function(){function a(d,e){return $CLJS.Be($CLJS.J.g($CLJS.DV(d,e),$CLJS.CM))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.sra=function(){function a(d){d=$CLJS.Y0(d);d=$CLJS.dz.h(d);return $CLJS.Wf.j($CLJS.xf,$CLJS.ik.g($CLJS.ef($CLJS.Qe($CLJS.Cd,d)),$CLJS.cf.h($CLJS.s3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$D,$CLJS.QD,$CLJS.dE,$CLJS.gE],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.tra=function(){function a(d,e,f,k){var l=$CLJS.h3(f)?$CLJS.h1(f):null,m=$CLJS.Wf.j($CLJS.Sg,$CLJS.ik.g($CLJS.cf.h($CLJS.h1),Vqa(function(t){return $CLJS.hk.g(t,l)})),$CLJS.D3.g(d,e));f=$CLJS.n(k)?k:$CLJS.h3(f)?k3($CLJS.z($CLJS.r3(f))):null;return w3(x3(d,e,f,$CLJS.jk.g(function(t){t=$CLJS.h1(t);return $CLJS.n(t)?$CLJS.Cd(m,t):null},$CLJS.X_.v(d,e,$CLJS.DV(d,e),new $CLJS.h(null,1,[$CLJS.A_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();
$CLJS.ura=function(){function a(d,e,f,k,l){k=$CLJS.h3(f)?$CLJS.t3(d,f):f;var m=$CLJS.h3(f)?$CLJS.h1(f):null;f=$CLJS.n(l)?l:$CLJS.h3(f)?l3($CLJS.z($CLJS.r3(f))):null;f=$CLJS.n(f)?$CLJS.Ra(m)?$CLJS.FD(f,$CLJS.bk,$CLJS.H([$CLJS.sO])):f:null;return w3(x3(d,e,f,$CLJS.cf.g(function(t){t=$CLJS.R.j(t,$CLJS.LJ,$CLJS.GY);return $CLJS.n(m)?$CLJS.i3(t,m):t},$CLJS.X_.v(d,e,k,new $CLJS.h(null,1,[$CLJS.A_,!1],null)))))}function b(d,e,f,k){return c.M?c.M(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.M=a;return c}();$CLJS.vra=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.Z1}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.Z1};return b}();
$CLJS.C3=function(){function a(d,e,f){function k(u,v){u=$CLJS.bk.g(u,$CLJS.o1);v=$CLJS.bk.g(v,$CLJS.o1);return $CLJS.b3.l($CLJS.W1.h($CLJS.Hq),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Be(Wqa(function(x){return $CLJS.Ji.h(y3.h(x))},hra(d,e,u,v)))}var m=$CLJS.DV(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.kk.g(function(v){return k(v,y3.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.kk.g(function(u){return k(y3.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.wra=$CLJS.cf.h(function(a){return $CLJS.R.j(a,$CLJS.LJ,$CLJS.GY)});
$CLJS.xra=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.h3(k)?k3($CLJS.z($CLJS.r3(k))):null;$CLJS.n(l)?(l=$CLJS.n_.j(e,f,l),l=$CLJS.Bz(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mj,$CLJS.lD],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.CV(e,f)){l=$CLJS.D3.g(e,f);var m=$CLJS.od(l);k=m?m:$CLJS.h3(k)?$CLJS.E.g($CLJS.VD.h(k),$CLJS.VD.h($CLJS.z(l))):null;k=$CLJS.n(k)?$CLJS.YZ(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.YZ(e),k=$CLJS.c_(e,k),e=$CLJS.k_.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.ID("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.yra=function(){function a(d,e,f,k){f=$CLJS.pV.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.di.h(k);e=$CLJS.CV(d,e);l=$CLJS.w0.j(d,e,l);d=$CLJS.w0.j(d,e,m);m=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.di,l)),k);d=null==k||$CLJS.Cd($CLJS.Wg($CLJS.cf.g($CLJS.di,d)),k);f=m?$CLJS.lk.v(f,2,$CLJS.f3,k):f;return d?$CLJS.lk.v(f,3,$CLJS.f3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.O_.m(null,$CLJS.CM,function(a,b){var c=$CLJS.Be($CLJS.D3.g(a,b));c=null==c?null:$CLJS.cf.g(function(d){return $CLJS.k_.j(a,b,d)},c);return null==c?null:$CLJS.hs(" + ",c)});