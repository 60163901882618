var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var D7,F7,H7,yta,J7,K7,zta;D7=function(a){if("string"===typeof a)return $CLJS.dh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.dh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.Hl(a));};$CLJS.E7=function(a){return $CLJS.Va(a)?$CLJS.Ly(a,$CLJS.H([$CLJS.ji,!0])):a};
F7=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=F7[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=F7._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.setting",a);}return a};$CLJS.G7=function(a,b){return F7($CLJS.b_(a),b)};H7=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.Uh("Unexpected Null passed to ceil",new $CLJS.h(null,1,[$CLJS.Ai,"ceil"],null));a=$CLJS.Rd(a);return 1<a?a:1};
yta=function(a,b,c){var d=D7(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.yz(d))/Math.log(10)));return $CLJS.Me(function(f){f*=e;return f>=d?f:null},$CLJS.dsa)};
$CLJS.I7=function(a,b,c){var d=$CLJS.g5(b);if($CLJS.n(d)){var e=$CLJS.BD.h(d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "num-bins":e=$CLJS.Bz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RM,$CLJS.Ui,$CLJS.qj],null));if($CLJS.n(e)){a=$CLJS.O(e);e=$CLJS.J.g(a,$CLJS.si);var f=$CLJS.J.g(a,$CLJS.Dj);a=$CLJS.O(d);a=$CLJS.J.g(a,$CLJS.jD);a=yta(e,f,a);return new $CLJS.h(null,3,[$CLJS.AD,a,$CLJS.FK,c,$CLJS.IK,c+a],null)}return null;case "bin-width":return a=$CLJS.O(d),a=$CLJS.J.g(a,$CLJS.AD),new $CLJS.h(null,
3,[$CLJS.AD,a,$CLJS.FK,c,$CLJS.IK,c+a],null);case "default":e=$CLJS.Bz(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RM,$CLJS.Ui,$CLJS.qj],null));if($CLJS.n(e)){d=$CLJS.O(e);e=$CLJS.J.g(d,$CLJS.si);f=$CLJS.J.g(d,$CLJS.Dj);a:switch(d=e,e=f,f=$CLJS.Oh instanceof $CLJS.M?$CLJS.Oh.S:null,f){case "num-bins":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,2,[$CLJS.jD,null,$CLJS.AD,D7(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AD,new $CLJS.h(null,
2,[$CLJS.AD,null,$CLJS.jD,H7(d,e,null)],null)],null);break a;case "default":$CLJS.SZ(b)?(a=$CLJS.G7(a,$CLJS.csa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AD,new $CLJS.h(null,2,[$CLJS.AD,a,$CLJS.jD,H7(d,e,a)],null)],null)):(a=$CLJS.G7(a,$CLJS.bsa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jD,new $CLJS.h(null,2,[$CLJS.jD,a,$CLJS.AD,D7(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.h(f)].join(""));}if($CLJS.n(a))return $CLJS.I(a,0,null),a=$CLJS.I(a,1,null),
a=$CLJS.O(a),a=$CLJS.J.g(a,$CLJS.AD),new $CLJS.h(null,3,[$CLJS.AD,a,$CLJS.FK,c,$CLJS.IK,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}}else return null};J7=function(a){var b=$CLJS.dz.h($CLJS.Y0(a));b=b.h?b.h($CLJS.B7):b.call(null,$CLJS.B7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.od($CLJS.s4.g(b,-1))&&$CLJS.od($CLJS.Q5.g(b,-1))){if(b=$CLJS.lk.j(b,$CLJS.pK,$CLJS.nd),!$CLJS.y($CLJS.pK.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
K7=function(a,b){var c=J7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.xU(b,new $CLJS.h(null,2,[$CLJS.HV,$CLJS.C7,$CLJS.MV,$CLJS.xta],null));var d=$CLJS.V_,e=d.j,f=a;var k=a;var l=$CLJS.H1(k,-1);k=$CLJS.n(l)?$CLJS.DV(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.T1.v(a,-2,$CLJS.oV(b),d);if($CLJS.n(b))a=$CLJS.lk.j(a,$CLJS.pK,$CLJS.nd);else return null}};
zta=function(a,b,c){var d=function(){var e=$CLJS.n($CLJS.g5(b))?function(){var f=$CLJS.O5(b,null);if(null!=c){var k=$CLJS.I7(a,b,c);if($CLJS.n(k)){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.FK);l=$CLJS.J.g(l,$CLJS.IK);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.N5(f,k),$CLJS.M5(f,l)],null)}return null}return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.F1($CLJS.KE,new $CLJS.P(null,1,5,$CLJS.Q,[f],null))],null)}():null;if($CLJS.n(e))return e;e=function(){var f=$CLJS.C7.h(b);return $CLJS.n(f)?$CLJS.f3(b,f):b}();return new $CLJS.P(null,
1,5,$CLJS.Q,[(0,$CLJS.Y2)(e,c)],null)}();return $CLJS.cb(function(e,f){return $CLJS.Z2.j(e,-1,f)},a,d)};
$CLJS.L7=function(a,b){b=$CLJS.O(b);var c=$CLJS.J.g(b,$CLJS.zK),d=$CLJS.J.g(b,$CLJS.GK);b=$CLJS.OV.l(a,-1,$CLJS.bk,$CLJS.H([$CLJS.ZE,$CLJS.YE,$CLJS.MQ,$CLJS.iR,$CLJS.RD]));b=$CLJS.cb(function(f,k){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.Bi);l=$CLJS.J.g(l,$CLJS.gj);return zta(f,k,l)},b,function(){return function l(k){return new $CLJS.ie(null,function(){for(var m=k;;)if(m=$CLJS.y(m)){if($CLJS.vd(m)){var t=$CLJS.ic(m),u=$CLJS.D(t),v=$CLJS.le(u);a:for(var x=0;;)if(x<u){var A=$CLJS.hd(t,x);$CLJS.hk.g($CLJS.LJ.h($CLJS.Bi.h(A)),
$CLJS.iK)&&v.add(A);x+=1}else{t=!0;break a}return t?$CLJS.oe($CLJS.qe(v),l($CLJS.jc(m))):$CLJS.oe($CLJS.qe(v),null)}v=$CLJS.z(m);if($CLJS.hk.g($CLJS.LJ.h($CLJS.Bi.h(v)),$CLJS.iK))return $CLJS.ae(v,l($CLJS.Hc(m)));m=$CLJS.Hc(m)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.ed(c);return $CLJS.n(f)?$CLJS.qG(function(k){return $CLJS.E.g($CLJS.p5(k),f)},$CLJS.s4.g(a,-1)):null}();return $CLJS.cb(function(f,k){return $CLJS.Z2.j(f,-1,k)},b,$CLJS.n(e)?function(){var f=$CLJS.z(e);switch(f instanceof
$CLJS.M?f.S:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ed(e)],null);case "metric":return $CLJS.$2.g($CLJS.R.j($CLJS.VX.h((0,$CLJS.AX)($CLJS.E1($CLJS.E7(new $CLJS.h(null,2,[$CLJS.Ui,$CLJS.FE,$CLJS.FE,$CLJS.E7($CLJS.EY.h($CLJS.P3(a,$CLJS.ed(e))))],null)),$CLJS.Ui,$CLJS.FE))),$CLJS.TO,$CLJS.TO.h(a)),-1);default:return null}}():null)};$CLJS.Y5.m(null,$CLJS.V6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.P6);b=$CLJS.J.g(b,$CLJS.MY);return new $CLJS.h(null,3,[$CLJS.Ui,$CLJS.V6,$CLJS.P6,a,$CLJS.MY,b],null)});
$CLJS.X5.m(null,$CLJS.V6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){return $CLJS.L7(J7(c),$CLJS.lk.j(e,$CLJS.GK,function(f){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){var u=t;if($CLJS.vd(u)){var v=$CLJS.ic(u),x=$CLJS.D(v),A=$CLJS.le(x);return function(){for(var G=0;;)if(G<x){var K=$CLJS.hd(v,
G);$CLJS.pe(A,$CLJS.lk.j(K,$CLJS.Bi,function(){return function(S){return K7(c,S)}}(G,K,v,x,A,u,t)));G+=1}else return!0}()?$CLJS.oe($CLJS.qe(A),m($CLJS.jc(u))):$CLJS.oe($CLJS.qe(A),null)}var C=$CLJS.z(u);return $CLJS.ae($CLJS.lk.j(C,$CLJS.Bi,function(){return function(G){return K7(c,G)}}(C,u,t)),m($CLJS.Hc(u)))}return null}},null,null)}(f)}))}a.A=3;a.B=function(c){var d=$CLJS.z(c);c=$CLJS.B(c);var e=$CLJS.z(c);c=$CLJS.B(c);var f=$CLJS.z(c);c=$CLJS.Hc(c);return b(d,e,f,c)};a.l=b;return a}());