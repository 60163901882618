var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.js");
'use strict';var Lta=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.tA);return $CLJS.Me(function(c){return $CLJS.Dz(b,c)},$CLJS.jE)},V7=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.pE($CLJS.gF,V7);$CLJS.pE($CLJS.$E,V7);
$CLJS.O_.m(null,$CLJS.MQ,function(a,b){var c=$CLJS.Be($CLJS.MQ.h($CLJS.DV(a,b)));return $CLJS.n(c)?$CLJS.gD("Sorted by {0}",$CLJS.H([$CLJS.I1($CLJS.ID("and"),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.k_.v(a,b,v,$CLJS.l_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),null)}t=$CLJS.z(k);
return $CLJS.ae($CLJS.k_.v(a,b,t,$CLJS.l_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.L_.m(null,V7,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.k_.v(a,b,c,d);e=e instanceof $CLJS.M?e.S:null;switch(e){case "asc":return $CLJS.gD("{0} ascending",$CLJS.H([a]));case "desc":return $CLJS.gD("{0} descending",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.T_.m(null,V7,function(a,b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.R.j($CLJS.n_.j(a,b,c),$CLJS.z_,d)});var W7,Mta=$CLJS.Se($CLJS.N),Nta=$CLJS.Se($CLJS.N),Ota=$CLJS.Se($CLJS.N),Pta=$CLJS.Se($CLJS.N),Qta=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ri,$CLJS.gB],null),$CLJS.Ri,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));W7=new $CLJS.Ph($CLJS.qh.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.XA,Qta,Mta,Nta,Ota,Pta);W7.m(null,V7,function(a){return $CLJS.nV(a)});
W7.m(null,$CLJS.Oh,function(a){if(null==a)throw $CLJS.Uh($CLJS.ID("Can''t order by nil"),$CLJS.N);return $CLJS.nV(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gF,$CLJS.oV(a)],null))});
$CLJS.Rta=function(){function a(d,e){d=W7.h(d);e=$CLJS.n(e)?e:$CLJS.gF;return $CLJS.R.j($CLJS.yf(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.gF):c.call(null,d,$CLJS.gF)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.X7=function(){function a(e,f,k,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=W7.h(k);return $CLJS.n(l)?$CLJS.R.j($CLJS.yf(t),0,l):t}();return $CLJS.OV.l(e,f,$CLJS.lk,$CLJS.H([$CLJS.MQ,function(t){return $CLJS.Yd.g($CLJS.yf(t),m)}]))}function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.v?d.v(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,
e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();$CLJS.Y7=function(){function a(d,e){return $CLJS.Be($CLJS.J.g($CLJS.DV(d,e),$CLJS.MQ))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Z7=function(){function a(d,e){var f=$CLJS.Be($CLJS.R5.g(d,e)),k=$CLJS.Be($CLJS.t4.g(d,e));f=$CLJS.n($CLJS.n(f)?f:k)?$CLJS.bf.g(f,k):function(){var t=$CLJS.DV(d,e);return $CLJS.X_.v(d,e,t,new $CLJS.h(null,1,[$CLJS.I_,!1],null))}();var l=$CLJS.ff(Lta,f);f=$CLJS.cf.g(function(t){$CLJS.I(t,0,null);$CLJS.I(t,1,null);return $CLJS.I(t,2,null)},$CLJS.Y7.g(d,e));if($CLJS.od(l))return null;if($CLJS.od(f))return $CLJS.yf(l);var m=$CLJS.Wf.j($CLJS.N,$CLJS.ik.g($CLJS.cf.h($CLJS.oV),$CLJS.D1(function(t,u){u=
$CLJS.T1.v(d,e,u,l);return $CLJS.n(u)?new $CLJS.P(null,2,5,$CLJS.Q,[u,t],null):null})),f);return $CLJS.kk.g(function(t){var u=m.h?m.h(t):m.call(null,t);return $CLJS.n(u)?$CLJS.R.j(t,$CLJS.v_,u):t},l)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.$7=new $CLJS.h(null,2,[$CLJS.gF,$CLJS.$E,$CLJS.$E,$CLJS.gF],null);$CLJS.Sta=function(){function a(d,e){return $CLJS.OV.l(d,e,$CLJS.bk,$CLJS.H([$CLJS.MQ]))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();