var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var CX,Ona,Pna,Qna,FX,Sna,Tna,Una,Vna,HX,IX,JX,LX,Wna,MX,NX,Xna,Yna,Zna,OX,Rna,PX,QX,$na,SX;CX=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.Xe(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.DX=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,A){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,A){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ne.M(a,u,v,x,A),$CLJS.Ne.M(b,u,v,x,A)],null)}m.A=3;m.B=function(u){var v=$CLJS.z(u);u=$CLJS.B(u);var x=$CLJS.z(u);u=$CLJS.B(u);var A=$CLJS.z(u);u=$CLJS.Hc(u);return t(v,x,A,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.w(A,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.EX=function(a,b){return $CLJS.PK(a)(b,$CLJS.xf,$CLJS.xf)};
Ona=function(a,b,c){return $CLJS.cb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.ZE,b),$CLJS.Wr(2,2,c))};Pna=function(a,b){var c=$CLJS.Dl(a,$CLJS.EP);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Cb(c);b=$CLJS.gs.g?$CLJS.gs.g(c,b):$CLJS.gs.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.EP,b)}return a};Qna=function(a){return $CLJS.bf.g($CLJS.KU(a),$CLJS.LU(a))};
FX=function(a,b,c){var d=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Dr,null,$CLJS.Rk,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.st(2,c):$CLJS.st(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?CX(2,c):CX(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.uW.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.ud(k)?$CLJS.Wf.j($CLJS.xf,$CLJS.jk.h($CLJS.Yk),l):$CLJS.sd(k)?$CLJS.Jz($CLJS.Sa,l):l}):$CLJS.bk.g(a,f)};
Sna=function(a){return $CLJS.cb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return FX(b,Rna,d)},a,Qna(a))};
Tna=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.pK.h(a),b);a:{var d=c,e=$CLJS.ND;$CLJS.ND=!0;try{for(var f=$CLJS.xf;;){var k=$CLJS.z($CLJS.cf.g($CLJS.DX($CLJS.Ui,$CLJS.Mk),$CLJS.ff($CLJS.ik.j(GX,$CLJS.z,$CLJS.Mk),$CLJS.Hr.h($CLJS.EX($CLJS.jV,d)))));if($CLJS.n(k)){var l=k,m=$CLJS.I(l,0,null),t=$CLJS.I(l,1,null),u=FX(d,m,t);l=$CLJS.xh;var v=l.l,x=m;var A=$CLJS.n(x)?x:$CLJS.UK($CLJS.EX($CLJS.jV,d));var C=v.call(l,$CLJS.H([A]));console.warn("Clean: Removing bad clause due to error!",t,C,$CLJS.tV($CLJS.z($CLJS.Zs(d,
u))));if($CLJS.E.g(u,d)){var G=d;break a}l=u;var K=$CLJS.Yd.g(f,new $CLJS.P(null,2,5,$CLJS.Q,[m,t],null));d=l;f=K}else{G=d;break a}}}finally{$CLJS.ND=e}G=void 0}e=Sna(G);if($CLJS.E.g(c,e)){if($CLJS.E.g(b,$CLJS.D($CLJS.pK.h(a))-1))return a;b+=1}else a=$CLJS.lk.M(a,$CLJS.pK,$CLJS.R,b,e)}};Una=function(a){var b=$CLJS.GV();return $CLJS.kk.g(function(c){return $CLJS.E.g($CLJS.VD.h(c),"__join")?$CLJS.lk.j(c,$CLJS.VD,b):c},a)};
Vna=function(a){return"string"===typeof $CLJS.dO.h(a)?$CLJS.bk.g($CLJS.R.j(a,$CLJS.MU,$CLJS.EV($CLJS.dO.h(a))),$CLJS.dO):a};HX=function(){return $CLJS.jk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.he(b))?(b=$CLJS.de(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};IX=function(a){return $CLJS.Wf.j($CLJS.N,HX(),a)};
JX=function(a){return $CLJS.Be($CLJS.Wf.j($CLJS.N,$CLJS.ik.g(HX(),$CLJS.jk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.oi)})),a))};
LX=function(a){var b=$CLJS.y(a);a=$CLJS.z(b);var c=$CLJS.B(b);b=$CLJS.z(c);c=$CLJS.B(c);var d=$CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.KX),c);a=$CLJS.E.g(a,$CLJS.SE)&&$CLJS.B(c)?$CLJS.Yd.g($CLJS.nd(d),new $CLJS.h(null,1,[$CLJS.Oh,$CLJS.md(d)],null)):d;b=$CLJS.Be(JX(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,a,b],null):a};Wna=function(a){return $CLJS.Wf.j($CLJS.xf,$CLJS.ik.g($CLJS.cf.h(function(b){return $CLJS.Ez(b,$CLJS.FA)}),$CLJS.cf.h($CLJS.KX)),$CLJS.AV.h(a))};
MX=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.pK);a=$CLJS.z($CLJS.cb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.KX.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.GP,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.QN,Wna(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.mU.h(c)],null)},null,a));return $CLJS.n($CLJS.hP.h(a))?$CLJS.xU(a,new $CLJS.h(null,1,[$CLJS.hP,$CLJS.FE],null)):a};
NX=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.RV(a,b,$CLJS.ik.g($CLJS.KX,$CLJS.z)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.RV(d,b,function(e){return $CLJS.Wf.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kr],null),$CLJS.cf.h($CLJS.KX),e)}):d;return $CLJS.xU(a,$CLJS.Ae([b,c]))};Xna=function(a){var b=$CLJS.MU.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.bk.g(a,$CLJS.MU),$CLJS.dO,["card__",$CLJS.p.h(b)].join("")):a};
Yna=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Zna=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);OX=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Rna=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);PX=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
QX=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.RX=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);$na=new $CLJS.M(null,"m","m",1632677161);SX=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var GX,aoa,boa,coa,doa,eoa,foa,goa,hoa,ioa,joa;$CLJS.TX=$CLJS.N;$CLJS.UX=$CLJS.N;GX=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.gV,null,$CLJS.RD,null,$CLJS.CM,null,$CLJS.YO,null,$CLJS.YE,null,$CLJS.MQ,null,$CLJS.ZE,null],null),null);aoa=$CLJS.Se($CLJS.N);boa=$CLJS.Se($CLJS.N);coa=$CLJS.Se($CLJS.N);doa=$CLJS.Se($CLJS.N);eoa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ri,$CLJS.gB],null),$CLJS.Ri,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.VX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.XA,eoa,aoa,boa,coa,doa);$CLJS.VX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){var b=$CLJS.md(a);var c=$CLJS.sd(b);a=c?$CLJS.nd(a):a;var d=$CLJS.y(a);a=$CLJS.z(d);d=$CLJS.B(d);b=$CLJS.nV($CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.VX),d))}else b=a;return b});$CLJS.VX.m(null,$CLJS.fV,function(a){return a});
$CLJS.VX.m(null,$CLJS.TU,function(a){var b=$CLJS.VX.h($CLJS.ZE.h(a)),c=$CLJS.Be($CLJS.kk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.xV($CLJS.VX.h(t),u)},$CLJS.YO.h(a))),d=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.xD);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.UX,k=$CLJS.TX;$CLJS.UX=d;$CLJS.TX=e;try{var l=Ona(Vna(a),b,$CLJS.H([$CLJS.YO,c])),m=$CLJS.cb(function(t,u){return $CLJS.Ra($CLJS.J.g(t,u))?t:$CLJS.lk.j(t,u,$CLJS.VX)},l,$CLJS.ck.l(GX,$CLJS.ZE,$CLJS.H([$CLJS.YO])));return $CLJS.n($CLJS.CM.h(m))?$CLJS.lk.j(m,$CLJS.CM,Una):m}finally{$CLJS.TX=k,$CLJS.UX=f}});$CLJS.VX.m(null,$CLJS.$U,function(a){return Pna(a,function(b){return $CLJS.RV(b,$CLJS.CE,$CLJS.VX)})});
$CLJS.VX.m(null,$CLJS.kK,function(a){a=$CLJS.lk.j($CLJS.lk.j(a,$CLJS.sK,$CLJS.VX),$CLJS.pK,$CLJS.VX);var b=$CLJS.n($CLJS.RD.h(a))?$CLJS.lk.j(a,$CLJS.RD,function(c){return $CLJS.jl(c)?$CLJS.kk.g($CLJS.VX,c):$CLJS.mh.h(c)}):a;return $CLJS.Ra($CLJS.VD.h(a))?$CLJS.R.j(b,$CLJS.VD,"__join"):b});$CLJS.VX.m(null,$CLJS.nA,function(a){return $CLJS.kk.g($CLJS.VX,a)});
$CLJS.VX.m(null,$CLJS.pA,function(a){return $CLJS.n($CLJS.Ui.h(a))?Tna($CLJS.R.j($CLJS.lk.j($CLJS.BV(a),$CLJS.pK,function(b){return $CLJS.kk.g($CLJS.VX,b)}),$CLJS.RX,!0)):$CLJS.gs(a,$CLJS.VX)});$CLJS.VX.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,a,b],null))});
$CLJS.VX.m(null,$CLJS.gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xU(a,new $CLJS.h(null,3,[$CLJS.cC,$CLJS.tA,$CLJS.CN,$CLJS.uj,$CLJS.UQ,$CLJS.sD],null));var c=$CLJS.R.j;var d=$CLJS.oi.h(a);$CLJS.n(d)||(d=$CLJS.tA.h(a),d=$CLJS.n(d)?d:$CLJS.LD(b));a=c.call($CLJS.R,a,$CLJS.oi,d);return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,a,b],null))});
$CLJS.VX.m(null,$CLJS.SE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.Oh.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,$CLJS.bk.g(c,$CLJS.Oh),$CLJS.kk.g($CLJS.VX,b)],null);b=$CLJS.nV(b);return null!=a?$CLJS.Yd.g(b,$CLJS.VX.h(a)):b});$CLJS.VX.m(null,$CLJS.qA,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.nV(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.VX.m(null,$CLJS.ZE,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.UX,c);if(!$CLJS.n(f))throw $CLJS.Uh(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.XQ,a],null));return $CLJS.nV(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.VX.m(null,$CLJS.tL,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.VX.h(b);var c=$CLJS.y(b);b=$CLJS.z(c);var d=$CLJS.B(c);c=$CLJS.z(d);d=$CLJS.B(d);return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Ak.l($CLJS.H([c,a]))],null),d)});foa=$CLJS.Se($CLJS.N);goa=$CLJS.Se($CLJS.N);hoa=$CLJS.Se($CLJS.N);ioa=$CLJS.Se($CLJS.N);joa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Ri,$CLJS.gB],null),$CLJS.Ri,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
$CLJS.KX=new $CLJS.Ph($CLJS.qh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.XA,joa,foa,goa,hoa,ioa);
$CLJS.KX.m(null,$CLJS.Oh,function(a){if($CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.z(a),c=$CLJS.B(a);a=$CLJS.z(c);var d=$CLJS.B(c);if($CLJS.sd(a)){c=$CLJS.Wf.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.KX,d);a=JX(a);a=c.call($CLJS.Wf,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.KX,$CLJS.ae(a,d)))}else a=$CLJS.sd(a)?$CLJS.gs(IX(a),$CLJS.KX):
a;return a});for(var WX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[SX,QX],null)),XX=null,YX=0,ZX=0;;)if(ZX<YX){var koa=XX.X(null,ZX);$CLJS.pE(koa,PX);ZX+=1}else{var $X=$CLJS.y(WX);if($X){var aY=$X;if($CLJS.vd(aY)){var bY=$CLJS.ic(aY),loa=$CLJS.jc(aY),moa=bY,noa=$CLJS.D(bY);WX=loa;XX=moa;YX=noa}else{var ooa=$CLJS.z(aY);$CLJS.pE(ooa,PX);WX=$CLJS.B(aY);XX=null;YX=0}ZX=0}else break}
for(var cY=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Rw,$CLJS.dF,$CLJS.sF,$CLJS.OE,$CLJS.Dj,$CLJS.wF,$CLJS.si,$CLJS.VE,$CLJS.qF,$CLJS.xF,$CLJS.vF,$CLJS.CF],null)),dY=null,eY=0,fY=0;;)if(fY<eY){var poa=dY.X(null,fY);$CLJS.pE(poa,SX);fY+=1}else{var gY=$CLJS.y(cY);if(gY){var hY=gY;if($CLJS.vd(hY)){var iY=$CLJS.ic(hY),qoa=$CLJS.jc(hY),roa=iY,soa=$CLJS.D(iY);cY=qoa;dY=roa;eY=soa}else{var toa=$CLJS.z(hY);$CLJS.pE(toa,SX);cY=$CLJS.B(hY);dY=null;eY=0}fY=0}else break}
for(var jY=$CLJS.y($CLJS.tf([$CLJS.Jq,$CLJS.Zr,$CLJS.Kq,$CLJS.DF,$CLJS.SE,$CLJS.mF,$CLJS.JE,$CLJS.FF,$CLJS.AE,$CLJS.XE,$CLJS.EE,$CLJS.hF,$CLJS.oF,$CLJS.GE,$CLJS.ZA,$CLJS.$A,$CLJS.Fx,$CLJS.VH,$CLJS.$H,$CLJS.PH,$CLJS.aB,$CLJS.UH,$CLJS.NH,$CLJS.cI,$CLJS.hI,$CLJS.eI,$CLJS.TH,$CLJS.jI,$CLJS.bB,$CLJS.eB,$CLJS.IE,$CLJS.BE,$CLJS.GF,$CLJS.VG,$CLJS.lF,$CLJS.Pw,$CLJS.yF,$CLJS.AF,$CLJS.HF,$CLJS.js,$CLJS.ps],!0)),kY=null,lY=0,mY=0;;)if(mY<lY){var uoa=kY.X(null,mY);$CLJS.pE(uoa,QX);mY+=1}else{var nY=$CLJS.y(jY);
if(nY){var oY=nY;if($CLJS.vd(oY)){var pY=$CLJS.ic(oY),voa=$CLJS.jc(oY),woa=pY,xoa=$CLJS.D(pY);jY=voa;kY=woa;lY=xoa}else{var yoa=$CLJS.z(oY);$CLJS.pE(yoa,QX);jY=$CLJS.B(oY);kY=null;lY=0}mY=0}else break}$CLJS.KX.m(null,PX,function(a){return LX(a)});$CLJS.KX.m(null,$CLJS.pA,function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.ik.g(HX(),$CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.KX.h(b)],null)})),a)});
$CLJS.KX.m(null,$CLJS.ZE,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.sd(b))try{var d=JX(b);b=$CLJS.Q;var e=$CLJS.TX,f=$CLJS.J.j(e,c,OX);if($CLJS.E.g(f,OX))throw $CLJS.Uh(["Unable to find key ",$CLJS.xh.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[$na,e,$CLJS.Vv,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.ZE,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,k,d],null):k}catch(l){throw c=l,d=$CLJS.rU(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.Th(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.KX.m(null,$CLJS.nA,function(a){return $CLJS.kk.g($CLJS.KX,a)});
$CLJS.KX.m(null,$CLJS.jF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.sd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,$CLJS.KX.h(a),JX(b)],null)});
$CLJS.KX.m(null,$CLJS.gj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=JX($CLJS.xU(b,new $CLJS.h(null,3,[$CLJS.tA,$CLJS.cC,$CLJS.uj,$CLJS.CN,$CLJS.sD,$CLJS.UQ],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,a,b],null)});
$CLJS.KX.m(null,$CLJS.kK,function(a){var b=IX(a);a=0==$CLJS.VD.h(a).lastIndexOf("__join",0)?$CLJS.bk.g(b,$CLJS.VD):b;return $CLJS.Ak.l($CLJS.H([$CLJS.gs($CLJS.bk.l(a,$CLJS.pK,$CLJS.H([$CLJS.sK])),$CLJS.KX),NX($CLJS.Cl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.sK],null)),$CLJS.sK,$CLJS.OQ),MX(a)]))});
$CLJS.KX.m(null,$CLJS.TU,function(a){var b=$CLJS.ZE.h(a),c=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.UX,e=$CLJS.TX;$CLJS.UX=c;$CLJS.TX=b;try{return $CLJS.cb(function(f,k){return $CLJS.RV(f,
k,$CLJS.KX)},NX($CLJS.RV($CLJS.RV(Xna(IX(a)),$CLJS.ZE,function(f){return $CLJS.kk.g(LX,f)}),$CLJS.YO,function(f){return $CLJS.Wf.g($CLJS.N,function(){return function m(l){return new $CLJS.ie(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.vd(t)){var u=$CLJS.ic(t),v=$CLJS.D(u),x=$CLJS.le(v);a:for(var A=0;;)if(A<v){var C=$CLJS.hd(u,A),G=$CLJS.KX.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wV(C),$CLJS.E.g($CLJS.gj,$CLJS.z(G))?$CLJS.dd(G):G],null);x.add(C);A+=1}else{u=!0;break a}return u?$CLJS.oe($CLJS.qe(x),
m($CLJS.jc(t))):$CLJS.oe($CLJS.qe(x),null)}x=$CLJS.z(t);u=$CLJS.KX.h(x);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wV(x),$CLJS.E.g($CLJS.gj,$CLJS.z(u))?$CLJS.dd(u):u],null),m($CLJS.Hc(t)))}return null}},null,null)}(f)}())}),$CLJS.gV,$CLJS.NE),$CLJS.ck.l(GX,$CLJS.ZE,$CLJS.H([$CLJS.gV,$CLJS.YO])))}finally{$CLJS.TX=e,$CLJS.UX=d}});$CLJS.KX.m(null,$CLJS.$U,function(a){return $CLJS.gs(IX(a),$CLJS.KX)});
$CLJS.KX.m(null,$CLJS.fV,function(a){try{var b=IX(a),c=$CLJS.tM.h(b),d=MX(b),e=$CLJS.E.g($CLJS.Vs.h($CLJS.ed($CLJS.pK.h(a))),$CLJS.$U)?$CLJS.hP:$CLJS.FE;return $CLJS.Ak.l($CLJS.H([$CLJS.bk.l(b,$CLJS.pK,$CLJS.H([$CLJS.tM,$CLJS.RX])),function(){var k=$CLJS.Ae([$CLJS.Ui,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.tM,c):k}()]))}catch(k){var f=k;throw $CLJS.Th(function(){var l=$CLJS.rU(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.FE,a],null),f);}});
$CLJS.qY=function(){function a(d,e,f){f=$CLJS.BX(null,$CLJS.Ly(f,$CLJS.H([$CLJS.ji,!0])));var k=$CLJS.DV(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.ZE);k=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.Wf.j($CLJS.N,$CLJS.El(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.xD);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.UX,t=$CLJS.TX;$CLJS.UX=k;$CLJS.TX=l;try{try{return $CLJS.VX.h(f)}catch(v){var u=v;throw $CLJS.Th(function(){var x=$CLJS.rU(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.FE,d,$CLJS.DK,e,Zna,f,Yna,$CLJS.UX],null),u);}}finally{$CLJS.TX=t,$CLJS.UX=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();