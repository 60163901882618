var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var rY,zoa,Aoa,Boa,Coa,yY,Doa,Eoa,zY,Foa,CY,Goa,Hoa,Ioa,HY,IY,Joa,Koa,RY,Loa,Moa,Noa,WY,Ooa;rY=function(){};$CLJS.sY=function(a){return null!=a?$CLJS.yc===a.ni?!0:a.Wc?!1:$CLJS.Ya(rY,a):$CLJS.Ya(rY,a)};zoa=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.tY=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Aoa=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Boa=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.uY=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.vY=new $CLJS.M(null,"database-id","database-id",1883826326);Coa=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.wY=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.xY=new $CLJS.M(null,"search","search",1564939822);yY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Doa=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Eoa=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
zY=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Foa=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.AY=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.BY=new $CLJS.M(null,"dataset","dataset",1159262238);CY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);
Goa=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.DY=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.EY=new $CLJS.M(null,"definition","definition",-1198729982);Hoa=new $CLJS.M(null,"state","state",-1988618099);$CLJS.FY=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Ioa=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.GY=new $CLJS.M("source","joins","source/joins",1225821486);
HY=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);IY=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.JY=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.KY=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);$CLJS.LY=new $CLJS.M(null,"selected?","selected?",-742502788);
Joa=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);Koa=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.MY=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.NY=new $CLJS.M("source","card","source/card",-139977973);$CLJS.OY=new $CLJS.M(null,"active","active",1895962068);$CLJS.PY=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.QY=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);RY=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Loa=new $CLJS.M(null,"details","details",1956795411);$CLJS.SY=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.TY=new $CLJS.M(null,"has-field-values","has-field-values",626648377);
Moa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.UY=new $CLJS.M("source","native","source/native",-1444604147);Noa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.VY=new $CLJS.M(null,"auto-list","auto-list",314197731);WY=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);
$CLJS.XY=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);Ooa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);$CLJS.YY=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.ZY=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.Y(HY,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.br,$CLJS.NY,$CLJS.UY,$CLJS.uY,$CLJS.wY,$CLJS.SY,$CLJS.iK,$CLJS.$J,$CLJS.GY,$CLJS.DY,$CLJS.FY],null));$CLJS.Y(zY,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),$CLJS.Cz($CLJS.Dd,new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.VY,null,$CLJS.xY,null,$CLJS.fj,null,$CLJS.cz,null],null),null))));
$CLJS.Y(RY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.hK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.SJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TJ,$CLJS.fK],null)],null));
$CLJS.Y(yY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.bK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.SJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.ur],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.ur],null)],null)],null));
$CLJS.Y($CLJS.AY,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.ts,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.JJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.xj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ji,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.fK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.dr,$CLJS.xj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oi,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.zD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.fK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.fK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YY,
new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HY],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.UJ],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,2,[$CLJS.si,1,$CLJS.Dj,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.TY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cK,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yY],null)],null)],
null)],null));$CLJS.Y(WY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MY,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[Goa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,2,5,$CLJS.Q,[Foa,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.zD],null)],null)],null)],null)],null)],null));
$CLJS.Y(CY,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OY,$CLJS.tr],null),new $CLJS.P(null,2,5,$CLJS.Q,[Hoa,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MY,$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WY],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,
$CLJS.qD],null)],null)],null));
$CLJS.Y(Moa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.gK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.UJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vY,$CLJS.VJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.Cj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QY,
new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dj,$CLJS.Cj],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.OJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Boa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,CY],null)],null)],null)],null));
$CLJS.Y(Joa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.NJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.MJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,$CLJS.OJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.Cj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Koa,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.PJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.KJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YJ,$CLJS.OJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.Cj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Hs,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Noa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.RJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.OJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,$CLJS.qD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lD,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.h(null,
1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.qD],null)],null)],null));
$CLJS.Y(Aoa,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.ts,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.dK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,$CLJS.VJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[Eoa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.Cj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Loa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.Cj],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.ZY,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.Hi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dz,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,$CLJS.Hi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ioa,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZJ,new $CLJS.h(null,1,[$CLJS.yr,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.Cj],null)],null)],null));
$CLJS.Y(IY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"Valid MetadataProvider"],null),new $CLJS.Cc(function(){return $CLJS.sY},Coa,$CLJS.zg([$CLJS.Gi,$CLJS.U,$CLJS.Ij,$CLJS.fi,$CLJS.Bi,$CLJS.Vi,$CLJS.Sj,$CLJS.ei,$CLJS.Ej,$CLJS.Hj],[zoa,Ooa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Fp],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.sY)?$CLJS.sY.H:null]))],null));
$CLJS.Y(Doa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cj,new $CLJS.h(null,1,[$CLJS.ts,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IY],null)],null)],null)],null));